import { useHotelQuery } from '../../../gql/queries';
import { useAppState } from '../../../providers/app-provider';
import { useWrappedRouter } from '../../../hooks/use-wrapped-router';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import { useFeatureToggleConfig } from '../../../hooks/use-feature-toggle';
import { useTranslation } from 'next-i18next';

import type { RateMessage } from '../../rate-name-cta/rate-name-cta';
import type { HotelType } from '../../../providers/app-provider/app-provider.types';
import { getMPAHotelRate } from '../utils/hotel-mpa-rate-utils';
import type { HotelCardData } from '../utils/hotel-lead-rate-utils';
import { getHotelLeadRateInfo } from '../utils/hotel-lead-rate-utils';
import { getLengthOfStay, getNoPhotoAvailable, getTransactionPropertyData } from '../../../utils';
import type { HotelBadgeTypes } from '../hotel-badges';
import {
  type HotelInfoParams,
  type HotelInfoAttributes,
  isIndividualRoomLimitExceeded,
} from '../utils/hotel-info-utils';
import { useBrandDetails, useIsAffiliateHotel, useRenovationChip } from '../../../hooks';
import { useLocation } from '@dx-ui/framework-location-provider';

export const useGetHotelInfo = ({
  priceInfo,
  hotelInfo,
  additionalParams,
}: HotelInfoParams): HotelInfoAttributes => {
  const { enabled: isImageAspectRatiosToggleEnabled } = useFeatureToggleConfig(
    'search-ui-aspect-ratios-images'
  );

  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const isDateFlex = queryParameters?.datesFlex || false;
  const { currenciesError, currenciesMap } = useCurrencyConversion();

  const numOfChildren =
    queryParameters?.rooms
      .map((room) => room.children)
      .reduce((previous: number, current: number) => previous + current) || 0;
  const {
    hasConnectingRooms,
    selectedCurrency = '',
    selectedCtyhocn,
    shouldUsePoints = false,
    pageType,
    hasSpecialRate,
  } = useAppState();
  const { isPointsExplorer, isDateSearch, isDreams } = pageType;
  const { t } = useTranslation(['hotel-card', 'rate-name-cta', 'locations-page']);
  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();

  //Disable hotel query if hotelInfo not passed
  const isHotelQueryEnabled = !hotelInfo && !!selectedCtyhocn && !isGuestLocationLoading;

  const { data, isLoading: isHotelDataLoading } = useHotelQuery(
    {
      language: 'en',
      ctyhocn: selectedCtyhocn as string,
      ratios: isImageAspectRatiosToggleEnabled ? 'threeByTwo' : undefined,
      input: { guestLocationCountry },
    },
    { enabled: isHotelQueryEnabled }
  );
  const hotelData = (hotelInfo || data?.hotel) as HotelCardData;

  const suppressBrandLogo = useIsAffiliateHotel(hotelData?.display);

  const { isPartnerBrand } = useBrandDetails({
    brandCode: hotelData?.brandCode,
  });

  const isIndividualRoomsOnly = isIndividualRoomLimitExceeded(
    priceInfo?.statusCode,
    queryParameters
  );

  const ctyhocn = hotelData?.ctyhocn;
  const { isRenoHotel } = useRenovationChip(hotelData?.ctyhocn);
  const isExternalResSystemSynix = hotelData?.externalResSystem === 'synxis';

  let hotelRateInfo: HotelInfoAttributes = {};
  if ((!!priceInfo && isDateSearch) || isPointsExplorer || isDateFlex)
    hotelRateInfo = getMPAHotelRate({
      numOfChildren,
      usePoints: shouldUsePoints,
      isDateFlex,
      hasSpecialRate,
      hotelData,
      priceInfo,
      t,
      locale: router.locale ?? 'en',
      pageType,
      ctyhocn,
      queryParameters,
      selectedCurrency,
      hasConnectingRooms,
      isPointsExplorer,
      currenciesError,
      currenciesMap,
      isIndividualRoomsOnly,
    });
  else if (isDreams) {
    hotelRateInfo = getHotelLeadRateInfo({
      hotelData,
      locale: router.locale ?? 'en',
      ctyhocn,
      usePoints: shouldUsePoints,
      selectedCurrency,
      hasConnectingRooms,
      currenciesError,
      currenciesMap,
      pageType,
      t,
      queryParameters,
    });
  }
  const {
    ctaHref,
    ctaLabel,
    ctaMessage,
    customParams,
    disclaimerMessages,
    hotelName,
    isNewHotel,
    isSaleHotel,
    messages,
    tripAdvisorLocationSummary,
  } = hotelRateInfo;

  const transactionPropertyData = queryParameters?.datesFlex
    ? undefined
    : hotelInfo
    ? getTransactionPropertyData({
        currenciesError: !!currenciesError,
        currenciesMap,
        hotel: hotelInfo as HotelType,
        isDateSearch: pageType.isDateSearch && !!priceInfo,
        lengthOfStay: getLengthOfStay(queryParameters?.departureDate, queryParameters?.arrivalDate),
        selectedCurrency: selectedCurrency || '',
        priceInfo,
        shouldUsePoints,
      })
    : undefined;

  const badges: HotelBadgeTypes[] = [];
  if (isNewHotel) badges.push('new');
  if (isSaleHotel) badges.push('sale');
  if (isRenoHotel) badges.push('reno');

  const masterImage = additionalParams?.isHotelCard
    ? hotelInfo?.images?.master || getNoPhotoAvailable('xs')[0]
    : undefined;

  const carouselImages =
    !!additionalParams?.isHotelCard &&
    hotelInfo?.images?.carousel &&
    hotelInfo?.images?.carousel.length > 0
      ? hotelInfo?.images?.carousel
      : undefined;

  const hotelUrl = hotelInfo?.facilityOverview?.homeUrlTemplate || '';

  let isLoading = isHotelQueryEnabled && isHotelDataLoading;
  if (isDateSearch && !isPointsExplorer && !isDateFlex) isLoading = isLoading || !priceInfo;

  const customHotelInfoParams = {
    ...customParams,
    isPartnerBrand: isPartnerBrand || isExternalResSystemSynix,
    ctyhocn: hotelInfo?.ctyhocn, //NHCSEARCH-5248
    hotelName, //NHCSEARCH-5248
  };

  return {
    badges,
    carouselImages,
    ctaLabel: ctaLabel as RateMessage,
    ctaHref,
    ctaMessage,
    customParams: customHotelInfoParams as { [key: string]: string | boolean },
    hotelName,
    hotelUrl,
    isHotelDataLoading: isLoading,
    masterImage,
    tripAdvisorLocationSummary,
    rateInfo: messages,
    disclaimerMessages,
    suppressBrandLogo,
    transactionPropertyData,
  };
};
