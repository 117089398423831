import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Dialog } from '@dx-ui/osc-dialog-v2';

type IneligibleHotelProps = {
  /**
   * displays the ineligible hotel modal or not
   */
  isShowing: boolean;
  /**
   * handler to close the ineligibleHotel
   */
  onClose?: () => void;
  /**
   * handler used to proceed if chosen
   */
  onContinue?: () => void;
};

/**
 * Used to display a ineligibleHotel when transitioning to a non translated page
 */
const IneligibleHotelModal: React.FC<React.PropsWithChildren<IneligibleHotelProps>> = ({
  isShowing,
  onClose: onCloseProp,
  onContinue: onContinueProp,
}) => {
  const [t] = useTranslation('rate-name-cta');
  const onContinue = () => {
    if (onContinueProp) onContinueProp?.();
  };
  const onClose = () => {
    if (onCloseProp) onCloseProp?.();
  };

  return (
    <Dialog isOpen={isShowing} onDismiss={onClose} title={t('ineligibleHotel.header')} size="sm">
      <div data-testid="ineligibleHotelDialog" className="mt-3">
        <div className="my-4 text-center leading-snug">{t('ineligibleHotel.body')}</div>
        <div className="mb-4">
          <button type="button" className="btn btn-primary btn-xl w-full" onClick={onContinue}>
            {t('ineligibleHotel.cta1')}
          </button>
        </div>
        <div className="mb-4">
          <button type="button" className="btn btn-primary-outline btn-xl w-full" onClick={onClose}>
            {t('ineligibleHotel.cta2')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default IneligibleHotelModal;
